import React from "react";
import { Button, Text } from "@adaptavant/core";

const LoadError = () => {
    const reloadPage = () => {
        window.location.reload();
    };
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Text className="font-stronger ml-2 text-body-20">There was an issue loading the AnySnap</Text>
      <Text className="mt-2 text-body-14">
        Try reloading the link to view the image
      </Text>
      <Button
        variant="accentPrimary"
        className="mt-4 text-body-16"
        size="large"
        onClick={reloadPage}
      >
        Reload
      </Button>
    </div>
  );
};

export default LoadError;
